import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';
import divisionPreviewData from '../../../../data/previews/division.yml';
import styles from './styles/division.module.scss';

const AvatarTeam = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.AvatarTeam;
    return <Component {...props} />;
  },
});

const AvatarUser = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.AvatarUser;
    return <Component {...props} />;
  },
});

const Checkbox = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Checkbox;
    return <Component {...props} />;
  },
});

const Headline = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Headline;
    return <Component {...props} />;
  },
});

const Subhead = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Subhead;
    return <Component {...props} />;
  },
});

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      divisionSelect: 'emptyspace',
    };
  }

  handleOptionChange = e => {
    this.setState({
      divisionSelect: e,
    });
  };

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Division"
        designCode
        subnav="guidelines">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Layout"
          tierThree="Division"
        />

        <PageNavigation links={['Intensity', 'Usage', 'Platform']} />

        <Section title="Intensity">
          <Paragraph>
            Related elements often appear close together, while those less
            connected sit farther apart.{' '}
            <Link href="/guidelines/layout/space" isDesignCodeLink>
              Space
            </Link>{' '}
            naturally communicates those relationships and should be used to
            subtly divide parts of the interface.
          </Paragraph>
          <Paragraph>
            For more intense divisions, there are lines and{' '}
            <Link href="/guidelines/layering/elevation" isDesignCodeLink>
              levels
            </Link>
            .
          </Paragraph>
          <ComponentPreview
            name="DivisionContainer"
            layout="fullBleed"
            hideEnvironmentToggle
            previewData={divisionPreviewData.options}
            onChange={this.handleOptionChange}>
            {this.state.divisionSelect === 'emptyspace' && (
              <div className={styles.emptySpace}>
                <div className={styles.emptySpaceLeft}>
                  <Subhead className="uni-margin--two--bottom">
                    Content Type
                  </Subhead>
                  <Checkbox label="Game" value="game" />
                  <Checkbox label="Practice" value="practice" />
                  <Checkbox label="Scout" value="scout" />
                  <Checkbox label="Playlist" value="playlist" />
                  <Checkbox label="File" value="file" />
                </div>
                <div className="uni-padding--two--top">
                  <Headline level="3">Yesterday</Headline>
                  <div className={styles.emptySpaceRow}>
                    <div className={styles.emptySpaceBox} />
                    <div className={styles.emptySpaceBox} />
                  </div>
                  <div className={styles.LevelDividerTransparent} />
                  <Headline level="3">Last Week</Headline>
                  <div className={styles.emptySpaceRow}>
                    <div className={styles.emptySpaceBox} />
                    <div className={styles.emptySpaceBox} />
                  </div>
                </div>
              </div>
            )}
            {this.state.divisionSelect === 'paddedlevelaccentdividers' && (
              <div className={styles.paddedLevelAccentDividers}>
                <div className={styles.UserBox}>
                  <div className={styles.UserBoxMain}>
                    <AvatarUser
                      size="large"
                      imageUrl="https://static.hudl.com/users/prod/3861535_eb9634eb3cb04a8fb6962db0b03a069e.jpg"
                    />
                    <div className="uni-margin--two--left">
                      <Headline level="2">John Jordan</Headline>
                      <Text color="nonessential">View Profile</Text>
                    </div>
                  </div>
                  <div className={styles.UserBoxDetails}>
                    <div>
                      <Text>
                        <strong>345</strong>
                      </Text>
                      <Text level="small" color="nonessential">
                        Views
                      </Text>
                    </div>
                    <div>
                      <Text>
                        <strong>987</strong>
                      </Text>
                      <Text level="small" color="nonessential">
                        Followers
                      </Text>
                    </div>
                    <div>
                      <Text>
                        <strong>291</strong>
                      </Text>
                      <Text level="small" color="nonessential">
                        Following
                      </Text>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.emptySpaceBoxColumn} />
                  <div className={styles.emptySpaceBoxColumn} />
                </div>
              </div>
            )}
            {this.state.divisionSelect === 'levelaccentdividers' && (
              <div className={styles.paddedLevelAccentDividers}>
                <div className={styles.UserBox}>
                  <div className={styles.UserBoxMain}>
                    <AvatarUser
                      size="large"
                      imageUrl="https://static.hudl.com/users/prod/3861535_eb9634eb3cb04a8fb6962db0b03a069e.jpg"
                    />
                    <div className="uni-margin--two--left">
                      <Headline level="2">John Jordan</Headline>
                      <Text color="nonessential">View Profile</Text>
                    </div>
                  </div>
                  <div className={styles.UserBoxDetails}>
                    <div>
                      <Text>
                        <strong>345</strong>
                      </Text>
                      <Text level="small" color="nonessential">
                        Views
                      </Text>
                    </div>
                    <div>
                      <Text>
                        <strong>987</strong>
                      </Text>
                      <Text level="small" color="nonessential">
                        Followers
                      </Text>
                    </div>
                    <div>
                      <Text>
                        <strong>291</strong>
                      </Text>
                      <Text level="small" color="nonessential">
                        Following
                      </Text>
                    </div>
                  </div>
                  <Subhead level="small" className="uni-margin--two--top">
                    Your Teams
                  </Subhead>
                  <div className={styles.UserBoxRow}>
                    <AvatarTeam
                      size="medium"
                      sport="volleyball"
                      className="uni-margin--half--right"
                    />
                    <div>
                      <Subhead>Varsity Volleyball</Subhead>
                      <Text level="micro" color="nonessential">
                        172 views
                      </Text>
                    </div>
                  </div>
                  <div className={styles.UserBoxRow}>
                    <AvatarTeam
                      size="medium"
                      sport="volleyball"
                      className="uni-margin--half--right"
                    />
                    <div>
                      <Subhead>Varsity Basketball</Subhead>
                      <Text level="micro" color="nonessential">
                        221 views
                      </Text>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.emptySpaceBoxColumn} />
                  <div className={styles.emptySpaceBoxColumn} />
                </div>
              </div>
            )}
            {this.state.divisionSelect === 'leveldividers' && (
              <div className={styles.emptySpace}>
                <div className={styles.emptySpaceLeft}>
                  <Subhead className="uni-margin--two--bottom">
                    Content Type
                  </Subhead>
                  <Checkbox label="Game" value="game" />
                  <Checkbox label="Practice" value="practice" />
                  <Checkbox label="Scout" value="scout" />
                  <Checkbox label="Playlist" value="playlist" />
                  <Checkbox label="File" value="file" />
                </div>
                <div className="uni-padding--two--top">
                  <Headline level="3">Yesterday</Headline>
                  <div className={styles.emptySpaceRow}>
                    <div className={styles.emptySpaceBox} />
                    <div className={styles.emptySpaceBox} />
                  </div>
                  <div className={styles.LevelDivider} />
                  <Headline level="3">Last Week</Headline>
                  <div className={styles.emptySpaceRow}>
                    <div className={styles.emptySpaceBox} />
                    <div className={styles.emptySpaceBox} />
                  </div>
                </div>
              </div>
            )}
            {this.state.divisionSelect === 'levelchange' && (
              <div className={styles.emptySpace}>
                <div className={styles.emptySpaceLeftLevelChange}>
                  <Subhead className="uni-margin--two--bottom">
                    Content Type
                  </Subhead>
                  <Checkbox label="Game" value="game" />
                  <Checkbox label="Practice" value="practice" />
                  <Checkbox label="Scout" value="scout" />
                  <Checkbox label="Playlist" value="playlist" />
                  <Checkbox label="File" value="file" />
                </div>
                <div className="uni-padding--two--top">
                  <Headline level="3">Yesterday</Headline>
                  <div className={styles.emptySpaceRow}>
                    <div className={styles.emptySpaceBox} />
                    <div className={styles.emptySpaceBox} />
                  </div>
                  <div className={styles.LevelDivider} />
                  <Headline level="3">Last Week</Headline>
                  <div className={styles.emptySpaceRow}>
                    <div className={styles.emptySpaceBox} />
                    <div className={styles.emptySpaceBox} />
                  </div>
                </div>
              </div>
            )}
            {this.state.divisionSelect === 'levelchangepadded' && (
              <div className={styles.emptySpace}>
                <div className={styles.emptySpaceLeftLevelChange}>
                  <Subhead className="uni-margin--two--bottom">
                    Content Type
                  </Subhead>
                  <Checkbox label="Game" value="game" />
                  <Checkbox label="Practice" value="practice" />
                  <Checkbox label="Scout" value="scout" />
                  <Checkbox label="Playlist" value="playlist" />
                  <Checkbox label="File" value="file" />
                  <div className={styles.LevelDivider} />
                  <Subhead className="uni-margin--two--bottom">
                    Custom Labels
                  </Subhead>
                  <Checkbox label="Offense" value="offense" />
                  <Checkbox label="Defense" value="defense" />
                  <Checkbox label="Backfield" value="backfield" />
                  <Checkbox label="Tackle Drills" value="tackledrills" />
                  <Checkbox label="Bottom 5" value="bottom5" />
                </div>
                <div className="uni-padding--two--top">
                  <Headline level="3">Yesterday</Headline>
                  <div className={styles.emptySpaceRow}>
                    <div className={styles.emptySpaceBox} />
                    <div className={styles.emptySpaceBox} />
                  </div>
                  <div className={styles.LevelDivider} />
                  <Headline level="3">Last Week</Headline>
                  <div className={styles.emptySpaceRow}>
                    <div className={styles.emptySpaceBox} />
                    <div className={styles.emptySpaceBox} />
                  </div>
                </div>
              </div>
            )}
            {this.state.divisionSelect === 'levelchangeaccent' && (
              <div className={styles.levelChangeAccent}>
                <div className={styles.levelChangeAccentLeftLevelChange}>
                  <Headline level="3" className="uni-margin--two--bottom">
                    Messages
                  </Headline>
                  <div className={styles.levelChangeAccentRow}>
                    <AvatarUser
                      size="medium"
                      initials="JJ"
                      className="uni-margin--half--right"
                    />
                    <div>
                      <Text level="large">John Jordan</Text>
                      <Text level="small" color="subtle" hideOverflow>
                        Hi Coach, what do I n...
                      </Text>
                    </div>
                  </div>
                  <div className={styles.levelChangeAccentRowSecond}>
                    <AvatarUser
                      size="medium"
                      initials="NA"
                      className="uni-margin--half--right"
                    />
                    <div>
                      <Text level="large">Nick Andrew</Text>
                      <Text level="small" color="subtle" hideOverflow>
                        I’ve shared a playlist w...
                      </Text>
                    </div>
                  </div>
                  <div className={styles.levelChangeAccentRowActive}>
                    <AvatarUser
                      size="medium"
                      initials="HM"
                      className="uni-margin--half--right"
                    />
                    <div>
                      <Text level="large">Hailey Michaels</Text>
                      <Text level="small" color="subtle" hideOverflow>
                        Did you see my latest...
                      </Text>
                    </div>
                  </div>
                  <div className={styles.levelChangeAccentRow}>
                    <AvatarUser
                      size="medium"
                      initials="AA"
                      className="uni-margin--half--right"
                    />
                    <div>
                      <Text level="large">All Athletes</Text>
                      <Text level="small" color="subtle" hideOverflow>
                        We need to focus next...
                      </Text>
                    </div>
                  </div>
                </div>
                <div
                  className="uni-padding--three"
                  style={{ paddingTop: '5.5rem' }}>
                  <Text>
                    Did you see my latest highlight reel? I want to send it to
                    Duke but I’m worried that they’ll think it’s trivial. What
                    would you add?
                  </Text>
                  <div className={styles.LevelAccentDivider} />
                  <Text>
                    I honestly think it’s great. Maybe you could use some of the
                    new Gatorade spot shadows?
                  </Text>
                </div>
              </div>
            )}
          </ComponentPreview>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Start with Space</SectionSubhead>
          <Paragraph>
            It can be tempting to gussy things up with a visual flourish, but we
            should always <strong>start with white space</strong> and gradually
            add elements to intensify any necessary divisions.
          </Paragraph>

          <DontDo
            dontText="start with the most intense level of division."
            doText="divide with white space and work your way up."
            imgFilename="division-intensity"
          />

          <SectionSubhead>Avoid Shadows</SectionSubhead>
          <Paragraph>
            <Link href="/guidelines/layering/shadows" isDesignCodeLink>
              Shadows
            </Link>{' '}
            are meant to differentiate multiple layers within an interface.{' '}
            <strong>
              They should never be used to divide elements sharing the same
              layer.
            </strong>
          </Paragraph>
          <DontDo
            dontText="separate content with shadows."
            doText="break apart content with levels and accent dividers."
            imgFilename="division-shadows"
          />
        </Section>

        <Section title="Platform">
          <PlatformTable />
        </Section>
      </PageWithSubNav>
    );
  }
}
